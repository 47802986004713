/* plugins:js */


@import url('https://gestion.tecmesh.com.ec/assets/css/style.css');
@import url('https://gestion.tecmesh.com.ec/assets/css/demo_1/style.css');

@import url('https://gestion.tecmesh.com.ec/assets/vendors/mdi/css/materialdesignicons.min.css');
@import url('https://gestion.tecmesh.com.ec/assets/vendors/flag-icon-css/css/flag-icon.min.css');
@import url('https://gestion.tecmesh.com.ec/assets/vendors/css/vendor.bundle.base.css');
@import url('https://gestion.tecmesh.com.ec/assets/vendors/jquery-bar-rating/css-stars.css');
@import url('https://gestion.tecmesh.com.ec/assets/vendors/font-awesome/css/font-awesome.min.css');

.spinner_class{
  margin-top: 10px;
  margin-bottom: 10px;
}

.spinner_class svg{
  display: block;
  margin: auto;
}
